import React, { Component } from "react";

export default class footer extends Component {
  render() {
    return (
      <>
        <div class="footer out-footer">
          <div class="copyright">
            <p>
              Copyright © by
              <a href="https://www.fastbat.co" target="_blank">
                Fast Bet
              </a>
              2024
            </p>
          </div>
        </div>
      </>
    );
  }
}
