import React, { useEffect, useState, useRef } from "react";
import Navbar from "../frontend/backend/navbar.js";
import Footer from "../frontend/backend/footer.js";
import Chatbox from "../frontend/backend/chatbox.js";
import HeaderRight from "../frontend/backend/header_right.js";
import LeftSidebar from "../frontend/backend/leftSidebar.js";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import jwt_decode from "jwt-decode";
import apiConfig from '../apiConfig';
import axios from "axios";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

const AgentEdit = ({ match }) => {
  const Id = match.params._id;
  let history = useHistory();

  // Token and user info
  const token = localStorage.getItem('jwtToken');
  const decodedToken = token ? jwt_decode(token) : null;
  const userInfo = decodedToken;

  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const currentPage = useRef(1);
  

  const [selectedAgentId, setSelectedAgentId] = useState(null);



  
  const setdata = (e) => {
    const { name, value } = e.target;
    setInpval({ ...inpval, [name]: value });
  };

  
  useEffect(() => {
    getPaginatedUsers();
  }, [searchQuery, limit]);

  const getPaginatedUsers = async () => {
    const search = searchQuery ? `&search=${searchQuery}` : "";
    const status = 1;  // Set a fixed status to filter
    try {
      const response = await fetch(
        `${apiConfig.baseURL}/api/agent/paginatedMasterAgentPhone?userId=${Id}&page=${currentPage.current}&limit=${limit}&status=${status}${search}`,
        { method: "GET" }
      );
      const data = await response.json();
      setPageCount(data.pageCount);
      setData(data.result);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };


  const handlePhoneNumberUpdate = async () => {
    try {
      const response = await axios.put(`${apiConfig.baseURL}/api/agent/updatePhone`, {
        phoneNumber: newPhoneNumber,
        id: inpval.id,
        wallet_method: inpval.wallet_method,
        wallet_type: inpval.wallet_type,
      });
      if (response.status === 200) {
        getPaginatedUsers();
        alert("Details updated successfully!");
        setIsModalOpen(false); 
      }
    } catch (error) {
      console.error("Error updating details:", error.message);
      alert("Failed to update details");
    }
  };

  
  const handlePageClick = (e) => {
    currentPage.current = e.selected + 1;
    getPaginatedUsers();
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [inpval, setInpval] = useState({
    wallet_method: "",
    id: "",
    wallet_type: ""
  });
  
  const openModal = (element) => {
    setNewPhoneNumber(element.agent_wallet);
    setInpval({
      wallet_method: element.wallet_method,
      wallet_type: element.wallet_type,
      id: element._id
    });
    setIsModalOpen(true);
  };



  const MyPaginate = styled(ReactPaginate).attrs({
    activeClassName: "active",
  })`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    padding: 0 2rem;
  
    li a {
      border-radius: 7px;
      padding: 0.1rem 1rem;
      border: gray 1px solid;
      cursor: pointer;
      margin: 0 5px;
    }
    .page-link{
      background:#26B19B;
    }
    li.previous a,
    li.next a,
    li.break a {
      border-color: transparent;
    }
    li.active a {
      background-color: #0366d6;
      border-color: transparent;
      color: white;
      min-width: 32px;
    }
    li.disabled a {
      color: grey;
      background-color: #26B19B !important;
    }
    li.disable,
    li.disabled a {
      cursor: default;
    }
  `;


  return (
    <>
      <Navbar />
      <Chatbox />
      <HeaderRight />
      <LeftSidebar />

      <div className="content-body">
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  <span style={{ fontWeight: "800" }}>{Id} : </span>
                  Master Agent Wallet List
                </h4>
              </div>

              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Method</th>
                        <th>Type</th>
                        <th>Wallet</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((element, id) => (
                        <tr key={id}>
                          <td>{element.wallet_method}</td>
                          <td>{element.wallet_type}</td>
                          <td>{element.agent_wallet}</td>
                          <td>
                            <button
                              className={`btn btn-${
                                element.status === "inactive" ? "danger" : "success"
                              } shadow btn-xs sharp`}
                            >
                              {element.status === "inactive" ? (
                                <>
                                  <i className="fa fa-times"></i> &nbsp;
                                </>
                              ) : (
                                <>
                                  <i className="fa fa-check"></i> &nbsp;
                                </>
                              )}
                            </button>
                          </td>
                          <td>
                            <div className="d-flex">
                          
                            <button
                              className="btn btn-primary"
                              onClick={() => openModal(element)}
                            >
                              Edit
                            </button>
                           
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <MyPaginate
                    breakLabel="..."
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={currentPage.current - 1}
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      {isModalOpen && (
  <div className="modal show" style={{ display: "block", background: "#000000a3" }}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Edit Wallet Details</h5>
          <button
            type="button"
            className="close"
            style={{ color: "red", background: "#25164F" }}
            onClick={() => setIsModalOpen(false)}
          >
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          {/* Phone Number Field */}
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              className="form-control"
              value={newPhoneNumber}
              onChange={(e) => setNewPhoneNumber(e.target.value)}
              placeholder="Enter new phone number"
            />
          </div>

          {/* Select Method */}
          <div className="form-group">
            <label>Select Method</label>
            <select
              name="wallet_method"
              id="wallet_method"
              value={inpval.wallet_method}
              onChange={setdata}
              className="form-control"
              required
            >
              <option value="">Select Method</option>
              <option value="bKash">bKash</option>
              <option value="Nagad">Nagad</option>
              <option value="Rocket">Rocket</option>
            </select>
          </div>

          {/* Select Type */}
          <div className="form-group">
            <label>Select Type</label>
            <select
              name="wallet_type"
              id="wallet_type"
              value={inpval.wallet_type}
              onChange={setdata}
              className="form-control"
              required
            >
              <option value="">Select Type</option>
              <option value="Personal">Personal</option>
              <option value="Merchant">Merchant</option>
              <option value="Agent">Agent</option>
            </select>
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handlePhoneNumberUpdate}
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
)}




      <Footer />
    </>
  );
};

export default AgentEdit;
