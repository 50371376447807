import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';


const LeftSidebar = () => {
  // logout
  const logoutUser = () => {
    localStorage.removeItem("jwtToken");
    window.location.replace("/");
  };


  const token = localStorage.getItem("jwtToken");
  const decodedToken = jwt_decode(token);
  const userInfo = decodedToken;
  const CheckStatus = userInfo.status;
  const user_role =  userInfo.role_as;
  const user_id =  userInfo.user_id;


  
  
  const secretKey = 'Maxx12#';    
  const encryptUserId = (userId) => {
    const encrypted = CryptoJS.AES.encrypt(userId, secretKey).toString();
    // Replace URL-unsafe characters
    const urlSafeEncrypted = encrypted
      .replace(/\//g, '_')  // Replace '/' with '_'
      .replace(/\+/g, '-')  // Replace '+' with '-'
      .replace(/=/g, '');   // Remove '=' padding
    return urlSafeEncrypted;
  };

  const encryptedUserId = encryptUserId(user_id);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/main.js';
    script.async = true;
    document.head.appendChild(script);
  }, []);

    
    return (
      <>
        <div className="deznav">
          <div className="deznav-scroll">
            <ul className="metismenu" id="menu">
              <li>
                <Link to="/admin" className="" ariaExpanded="false">
                  <i class="material-icons">description</i>
                  <span class="nav-text">Master Admin Dashboard</span>
                </Link>
              </li>

         
              {/* 
              <li>
                <Link
                  to="/affiliate-index"
                  // className="has-arrow "
                  ariaExpanded="false"
                >
                  <i className="material-icons">trending_up</i>
                  <span className="nav-text">Agent Create </span>
                </Link>
              </li> 
              */}

              <li>
                <Link to="/agent-balance-report" className="" ariaExpanded="false">
                  <i class="material-icons">description</i>
                  <span class="nav-text">Balance Transfer</span>
                </Link>
              </li>

              {/* <li>
                <Link
                  to="/agent-balance-report"
                  className=""
                  ariaExpanded="false"
                >
                  <i class="material-icons">description</i>
                  <span class="nav-text">Country Agent Balance Tr. Report</span>
                </Link>
              </li> */}

            
              
              <li>
                <Link
                  to="/agent-create"
                  // className="has-arrow "
                  ariaExpanded="false"
                >
                  <i className="material-icons">trending_up</i>
                  <span className="nav-text">Super Admin Create </span>
                </Link>
              </li>
              
              <li>
                <Link
                  to="/super-admin"
                  // className="has-arrow "
                  ariaExpanded="false"
                >
                  <i className="material-icons">trending_up</i>
                  <span className="nav-text">Super Admin </span>
                </Link>
              </li>

              <li>
                <Link to="/agent-index" className="" ariaExpanded="false">
                  <i class="material-icons">description</i>
                  <span class="nav-text">Admin List</span>
                </Link>
              </li>

              <li>
                <Link to="/super-agent" className="" ariaExpanded="false">
                  <i class="material-icons">description</i>
                  <span class="nav-text">Super Agent List</span>
                </Link>
              </li>

              <li>
                <Link to="/master-agent" className="" ariaExpanded="false">
                  <i class="material-icons">description</i>
                  <span class="nav-text">Master Agent List</span>
                </Link>
              </li>
               
              
              <li>
                <Link to="/user-index" className="" ariaExpanded="false">
                  <i class="material-icons">description</i>
                  <span class="nav-text">User List</span>
                </Link>
              </li>
              
              <li>
                <Link to="/daily-deposit" className="" ariaExpanded="false">
                  <i class="material-icons">description</i>
                  <span class="nav-text">Daily Deposit List</span>
                </Link>
              </li>
              
              <li>
                <Link to="/daily-withdraw" className="" ariaExpanded="false">
                  <i class="material-icons">description</i>
                  <span class="nav-text">Daily withdraw List</span>
                </Link>
              </li>
              
              <li>
                <Link to="/casino-list" className="" ariaExpanded="false">
                  <i class="material-icons">description</i>
                  <span class="nav-text">Casino Games List</span>
                </Link>
              </li>
        
              <li>
                <Link  to={`/down-list/${encryptedUserId}`} className="" ariaExpanded="false">
                  <i class="material-icons">description</i>
                  <span class="nav-text">Downline List</span>
                </Link>
              </li>
              
              <li>
                <Link to="/aa" ariaExpanded="false" >
                  <i className="material-icons">trending_up</i>
                  <span className="nav-text">Notice Panel(Multiple users ma ca agent) </span>
                </Link>
              </li>
       
              
              <li>
              <Link to="#" ariaExpanded="false" >
                <i className="material-icons">trending_up</i>
                <span className="nav-text">Promotion Manage </span>
              </Link>
              </li>
              
              <li>
              <Link to="#" ariaExpanded="false" >
                <i className="material-icons">trending_up</i>
                <span className="nav-text">Daily User Report </span>
              </Link>
              </li>
              


              <li>
                <a href="javascript:void(0);" className="" ariaExpanded="false">
                  <i class="material-icons">description</i>
                  <span class="nav-text">Activity Logs</span>
                </a>
              </li>

              <li>
                <a
                  href="javascript:void(0);"
                  className="has-arrow "
                  ariaExpanded="false"
                >
                  <i className="material-icons">trending_up</i>
                  <span className="nav-text">Settings </span>
                </a>

                <ul ariaExpanded="false">
                  <li>
                    <a
                      href="javascript:void(0);"
                      className="has-arrow "
                      ariaExpanded="false"
                    >
                      Basic Info
                    </a>

                    <ul ariaExpanded="false">
                      <li>
                        <Link to="/basic-setting-list">Site Setting</Link>
                      </li>

                      <li>
                        <Link to="/slide-manage">Slide Manage</Link>
                      </li>

                      <li>
                        <Link to="/favicon-setting-list">Others</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/send-money-limit-list">Send Money Limit</Link>
                  </li>

                  {/* <li>
                    <Link to="/permission-setting">Permission Setting</Link>
                  </li> */}

                  <li>
                    <Link to="/notice-manage">Notice Manage </Link>
                  </li>

                  <li>
                    <Link to="/promo-code-list">Promo Code Manage</Link>
                  </li>

                  <li>
                    <a href="javascript:void(0);">SMS Setup </a>
                  </li>

                  <li>
                    <a href="javascript:void(0);">Payment Gateway </a>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="javascript:void(0);"
                  className="has-arrow "
                  ariaExpanded="false"
                >
                  <i className="material-icons">trending_up</i>
                  <span className="nav-text">Profile Manage </span>
                </a>

                <ul ariaExpanded="false">
                  <li>
                    <Link to="/profile-manage">Profile Setting</Link>
                  </li>

                  <li>
                    <Link to="/password-manage">Password Manage</Link>
                  </li>

                  <li>
                    <Link to="/tpin-manage">S-Pin Manage</Link>
                  </li>
                </ul>
              </li>

              <li>
                <a href="#" className="" ariaExpanded="false">
                 <button className="btn btn-danger" onClick={logoutUser}>
                    Logout
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  
}


export default LeftSidebar;